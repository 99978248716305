const dayMap = {
  SU: "Sunday",
  MO: "Monday",
  TU: "Tuesday",
  WE: "Wednesday",
  TH: "Thursday",
  FR: "Friday",
  SA: "Saturday"
};
function convertDay(byDayValue) {
  return dayMap[byDayValue] || "";
}
export function SeriesScheduleFromICalText(iCalText, duration) {
  const dtStartMatch = iCalText.match(/DTSTART;TZID=(.*):(\d+T\d+)/);
  const timeZoneId = dtStartMatch ? dtStartMatch[1] : null;
  const dtStart = dtStartMatch ? dtStartMatch[2] : null;
  const beginDate = dtStart ? dtStart.substring(0, 8) : null;
  const startTime = dtStart ? dtStart.substring(9) : null;
  // Extracting recurrence rule information
  const rruleMatch = iCalText.match(/RRULE:FREQ=WEEKLY;(INTERVAL=(\d+);)?BYDAY=([A-Z,]+)/);
  const weeklyFrequency = rruleMatch && rruleMatch[2] ? rruleMatch[2] : "1";
  const byDay = rruleMatch ? rruleMatch[3] : null;
  const byDayFull = convertDay(byDay);
  // Extracting and calculating duration
  const durationHHMMSS = duration;
  const durationParts = durationHHMMSS.split(":");
  const hours = parseInt(durationParts[0], 10);
  const minutes = parseInt(durationParts[1], 10);
  const durationMinutes = (hours * 60 + minutes).toString();
  const result = {
    beginDate,
    startTime,
    weeklyFrequency,
    weeklyFrequencyText: weeklyFrequency === "1" ? "Weekly" : "Every Other Week",
    timeZoneId,
    durationMinutes,
    byDay: byDayFull
  };
  return result;
}

export function ParseDurationMinutesFromDuration(duration) {
  const durationHHMMSS = duration;
  const durationParts = durationHHMMSS.split(":");
  const hours = parseInt(durationParts[0], 10);
  const minutes = parseInt(durationParts[1], 10);
  const durationMinutes = (hours * 60 + minutes).toString();
  return durationMinutes;
}

export function getDisplayLocation(city, region, country) {
  if (city && region) return `${city}, ${region}`;
  if (city) return `${city}, ${country}`;
  if (region) return `${region}, ${country}`;
  return country;
}

export function UserChip(user) {
  let aColor = user.avatar_color ? user.avatar_color : "blue";
  let aType = !user.is_avatar_hidden && user.avatar_url ? "picture" : aColor;
  let aAvatar = !user.is_avatar_hidden && aType === "picture" ? user.avatar_url : user.initials;

  return {
    id: user.id,
    photo: user.avatar_url,
    chipType: "all",
    icon: "IconCloseSmall",
    // text needed for searchInput to work. label for ChipList to work.
    text: user.display_name,
    label: user.display_name,
    avatarType: aType,
    avatarContent: aAvatar,
    role: user.role
  };
}
